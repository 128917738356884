import api from '../api'
/**
 * 快速查询
 */
export class LabelQuickSearchApi extends api {
    getLabelInfoByLabelNum (labelNum) {
        return this.request({
          url: `${this.servicePath}/${this.modulePath}/getLabelInfoByLabel/${labelNum}`,
          method: 'get'
        })
      }
}

export default new LabelQuickSearchApi('quickSearch')