<template>
  <div>
    <div>
      <el-row style="margin-left:30%;margin-top:10%">
        <el-form-item>
          <el-input maxlength="24" show-word-limit size="small" style="width: 280px;" placeholder="" v-model="labelNum" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="filter-item" size="small" style="margin-left: 10px;" type="primary" v-waves icon="el-icon-search" @click="getLabelInfo">查询</el-button>
        </el-form-item>
      </el-row>
    </div>
    <el-table :data="labelList" style="width: 100%" border id="labelTable">
      <el-table-column label="物料代码" property="name" align="center" width="120px">
        <template #default="scope">
          <span>{{scope.row.model.materialCode}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="labelApply.creatorName" label="申请人" width="100" align="center"/>
      <el-table-column label="型号" align="center" min-width="100px">
        <template #default="scope">
          <span>{{scope.row.model.name}}</span>
        </template>
      </el-table-column>
      <el-table-column width="50px" label="单位" align="center">
        <template #default="scope">
          <span>{{scope.row.model.unit}}</span>
        </template>
      </el-table-column>
      <el-table-column label="类型" width="80px" align="center">
        <template  #default="scope">
          <el-tag :type="showFilter(scope.row)">{{scope.row.model.type && scope.row.model.type.title || ''}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="manufacturer.name" label="制造商" min-width="200px" align="center"/>
      <el-table-column prop="manufacturer.code" label="制造商代码" width="70" align="center"/>
      <el-table-column prop="model.page" label="联数" width="50" align="center"/>
      <el-table-column prop="labelCount" label="防伪码标签（套）" width="80" align="center"/>
      <el-table-column prop="startLabel" label="起始标签" width="220px" align="center"/>
      <el-table-column prop="endLabel" label="结束标签" width="220px" align="center"/>
      <el-table-column prop="order.orderBatch" label="订单批次" width="150" align="center"/>
    </el-table>
  </div>
</template>

<script>
import labelQuickSearchApi from '@/apis/label/quickSearch'
export default {
  props: {
    id: {
      type: String
    }
  },
  data() {
    return {
      labelNum: undefined,
      labelInfo: undefined,
      labelList: []
    }
  },
  created() {
  },
  mounted(){
  },
  methods:{
    showFilter (row) {
      return row.model.type && row.model.type.value === 'HOME_APPLIANCES' ? 'success' : 'warning'
    },
    getLabelInfo(){
      if(!this.labelNum){
        this.$message.warning("请输入标签进行查询")
      }else if(this.labelNum.length != 24){
        this.$message.warning("请输入24位标签进行查询")
      }else{
        labelQuickSearchApi.getLabelInfoByLabelNum(this.labelNum).then(resp=>{
          this.labelList = resp.data
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>